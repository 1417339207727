<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Trajets à venir",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Trajets à venir",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets à venir",
          active: true,
        },
      ],
      idtrajet: 0,
      totalRows: 1,
      currentPage: 1,


      filter: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "itineraire", sortable: true },
        { key: "conducteur", sortable: true },
        { key: "tel", sortable: true },
        { key: "Depart", sortable: true },
        { key: "nombrePlace", sortable: true, label: "Places" },
        { key: "prix", sortable: true },
        { key: "vues", sortable: true },
        { key: "passagers", sortable: true },
        { key: "date", sortable: true },
        { key: "moyen", sortable: true },
        { key: "inter", sortable: true, label: "Type trajet" },
        { key: "action", label: "Actions" },
      ],
      idtrajetToUse: 0,
      user: null,
      showDelete: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetAvenir: [],
      newStart: false,
      recherche: "",
      libelLoader: "Chargement en cours...",
      parBon: 500

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    this.totalInfo()
    //Chargement des données
    this.init();
  },
  methods: {

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/trajets/avenir",
        undefined,
        false
      );
      if (data && data.data) {
        this.totalValue = data.data.total;

        const formattedTable = data.data.trajets.map((trajet) => {
          var passagers = "";
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              passagers +=
                "-" + element.firstname + " " + element.lastname + "\n";
            }
          });

          var type = trajet.conducteur.userType == "" ? "" : trajet.conducteur.userType;
          if (trajet.conducteur.userType == "PARTICULIER" || trajet.conducteur.userType == "CONDUCTEUR") {
            if (!trajet.conducteur.userType.vehicule) {
              type = "PASSAGER";
            } else if (trajet.conducteur.userType == "CONDUCTEUR") {
              type = "MOTO";
            } else if (trajet.conducteur.userType == "PARTICULIER") {
              type = "VOITURE";
            }
          }

          if (trajet.conducteur.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (trajet.conducteur.userType == "SOCIETE") {
            type = "BUS";
          }

          return {
            id: trajet.id,
            itineraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦ " +
              trajet.lieuArrivee.nom.split(",")[0],
              conducteurId: trajet.conducteur.id,
            conducteur:
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname,
            tel:
              trajet.conducteur.personne.indicatifTel +
              "" +
              trajet.conducteur.personne.tel,
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            nombrePlace: trajet.nombrePlace,
            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
            passagers: passagers,
            vues: trajet.vues ? trajet.vues : 0,

            moyen: type,
            inter: trajet.inter

          };
        });
        this.tableData = formattedTable;
        this.totalRows = formattedTable.length
      }
      // Set the initial number of items

    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {

      router.push(`/trajets/details_avenir/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
    cancelAction(row) {
      this.idtrajetToUse = row.id;

      this.showDelete = true;
    },
    async desactivetrajet() {
      (this.user = JSON.parse(localStorage.getItem("user"))),
        (this.showDelete = false);
      const data = await apiRequest(
        "POST",
        "admin/annuler-trajet",
        {
          idTrajet: this.idtrajetToUse,
          motifAnnulation:
            "Annuler par l'administrateur " +
            this.user.firstname +
            " " +
            this.user.lastname,
        },
        false
      );

      if (data && data.data) {
        this.init();

        this.$toast.success("Opération effectuée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      } else {


        this.$toast.error("Une erreur est survenue !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      }
    },

    /** Pagination System **/

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/trajets/recherche-avenir?page=" + this.startValue + "&limit=" + this.endValue,

        { mots: this.recherche },
        false
      );
      const formattedTable = dataSearch.data.map((trajet) => {
        var passagers = "";
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        });
        return {
          id: trajet.id,
          itineraire:
            trajet.lieuDepart.nom.split(",")[0] +
            " ↦ " +
            trajet.lieuArrivee.nom.split(",")[0],
            conducteurId: trajet.conducteur.id,

          conducteur:
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname,
          tel:
            trajet.conducteur.personne.indicatifTel +
            "" +
            trajet.conducteur.personne.tel,
          Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
          passagers: passagers,
          vues: trajet.vues ? trajet.vues : 0,
        };
      });

      this.newDatTrajetAvenir = formattedTable;
      this.rowsdataUser = formattedTable.length;

      this.totalRows = formattedTable.length;
      this.userChargeLoader = false;
    },
    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets/avenir?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.trajets.map((trajet) => {
          var passagers = "";
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              passagers +=
                "-" + element.firstname + " " + element.lastname + "\n";
            }
          });
          return {
            id: trajet.id,
            itineraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦ " +
              trajet.lieuArrivee.nom.split(",")[0],
              conducteurId: trajet.conducteur.id,

            conducteur:
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname,
            tel:
              trajet.conducteur.personne.indicatifTel +
              "" +
              trajet.conducteur.personne.tel,
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            nombrePlace: trajet.nombrePlace,
            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
            passagers: passagers,
            vues: trajet.vues ? trajet.vues : 0,
          };
        });
        this.newDatTrajetAvenir = formattedTable;
        this.rowsdataUser = formattedTable.length;

        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
      }
    },


    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;

      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },


    async totalInfo() {
      //Chargement des info
      const data = await apiRequest("GET", "admin/dash-info", undefined, false);
      if (data && data.data) {


        this.totalValue = data.data.trajetsAVenir;
      }

      //Location

    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.inter === true) return "table-warning";
    },


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="containerDot" v-if="tableData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">

            <div class="row d-flex justify-content-between align-items-center p-2">
              <div>
                <h4 class="card-title mt-4">
                  Nombre total de trajets : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == this.parBon"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="row d-flex justify-content-between mt-4">

              <!-- Search -->
              <div class="col-md-3 text-left">
                <span class="superuser mb-1"></span>&nbsp; Intermédiaire
              </div>
              <div class="inputCol p-3">
                <div class="input ">
                  <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                    @keyup.enter="userSearch" />
                </div>

              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 mt-3">
              <b-table :items="newDatTrajetAvenir.length != 0 ? newDatTrajetAvenir : tableData" :fields="fields" responsive="sm"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="goToDetail"
                :tbody-tr-class="rowClass">
                <template v-slot:cell(tel)="row" style="text-align:center;">
                  <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                    title="Envoyer un message whatsapp" target="_blank">
                    {{ row.value }}
                  </a>
                </template>

                <template v-slot:cell(conducteur)="row">

                  <div @click="goToUserDetail(row.item.conducteurId)">
                    <span>
                      {{ row.item.conducteur }}
                    </span>
                    <i class="fas fa-eye"></i>

                  </div>


                </template>

                <template v-slot:cell(inter)="row" style="text-align:center;">
                  <span class="interTrajet">{{ row.item == 'True' ? 'Intermédiaire' : 'Simple' }}</span>
                </template>


                <template v-slot:cell(action)="row">
                  <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="cancelAction(row.item)"
                    title="Désactiver">
                    <i class="mdi mdi-close font-size-18"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                          data-placement="bottom" title="Prem">
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == this.parBon"
                          data-toggle="tooltip" data-placement="bottom" title="Préc">
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                          title="Suiv" :disabled="endValue >= totalValue">
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                          title="Dern" :disabled="endValue >= totalValue">
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment annuler ce trajet ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivetrajet">Oui</b-button>
      </div>
    </b-modal>
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}


.input {

  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 260px;
  height: 35px;
  top: 0;


  flex: 60%;

  margin: 0 0 10px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #d6d2f8;
}

.inputCol {
  position: relative;
  justify-content: flex-end;
  margin-right: 13px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}



.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -18%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}


/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */
.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}


.interTrajet {

  font-size: 17px;
  color: #faac1a;

}


.NotinterTrajet {
  padding: 10px 20px;
  font-size: 17px;
  box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
  color: #cecece;
}

span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}
</style>